
import { get } from 'vuex-pathify';
import { genChip } from '@/utils/componentHelpers';
import { waitForReadystate } from '@/utils/componentHelpers';

import app from '@/mixins/app';
import mixins from '@/utils/mixins';

export default mixins(app).extend({
  name: 'drawer',
  data: () => ({
    duration: 300,
    offset: 0,
    easing: 'easeInOutCubic',
    items: {
      privacy: [
        {
          text: 'Introduction',
          target: 'privacy'
        },
        {
          text: 'Interpretation and Definitions',
          target: 'interpretation-and-definitions'
        },
        {
          text: 'Interpretation',
          target: 'interpretation'
        },
        {
          text: 'Definitions',
          target: 'definitions'
        },
        {
          text: 'Collecting and Using Your Personal Data',
          target: 'collecting-personal-data'
        },
        {
          text: 'Personal Data',
          target: 'personal-data'
        },
        {
          text: 'Usage Data',
          target: 'usage-data'
        },
        {
          text: 'Information Collected',
          target: 'info-collected'
        },
        {
          text: 'Use of Your Personal Data',
          target: 'use-of-personal-data'
        },
        {
          text: 'Retention',
          target: 'retention'
        },
        {
          text: 'Transfer',
          target: 'transfer'
        },
        {
          text: 'Disclosure',
          target: 'disclosure'
        },
        {
          text: 'Security of Your Personal Data',
          target: 'security-of-personal-data'
        }
      ],
      terms: [
        {
          text: 'Introduction',
          target: 'introduction'
        },
        {
          text: 'Intellectual Property',
          target: 'intellectual-property'
        },
        {
          text: 'Use of Company Materials',
          target: 'company-materials'
        },
        {
          text: 'Account and Account use',
          target: 'account-use'
        },
        {
          text: 'Payment',
          target: 'payment'
        },
        {
          text: 'Sale of Goods and Services',
          target: 'goods-and-services'
        },
        {
          text: 'Acceptable Use',
          target: 'acceptable-use'
        },
        {
          text: 'Protection of privacy',
          target: 'protection-of-privacy'
        },
        {
          text: 'Reverse Engineering & Security',
          target: 'engineerring-security'
        },
        {
          text: 'Data loss',
          target: 'data-loss'
        },
        {
          text: 'Idemnification',
          target: 'idemnification'
        },
        {
          text: 'Spam Policy',
          target: 'spam-policy'
        },
        {
          text: 'Third-Party links & Content',
          target: 'third-party-content'
        },
        {
          text: 'Variation',
          target: 'variation'
        },
        {
          text: 'Service Interruptions',
          target: 'service-interruptions'
        },
        {
          text: 'Termination of Account',
          target: 'terminitation-of-account'
        },
        {
          text: 'No Warranties',
          target: 'no-warranties'
        },
        {
          text: 'Privacy',
          target: 'privacy'
        },
        {
          text: 'Limitation of Liability',
          target: 'limitation-of-liability'
        }
      ]
    }
  }),
  computed: {
    options(): object {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
        appOffset: true
      };
    }
  },

  watch: {
    $route() {
      if (this.drawer && this.$vuetify.breakpoint.mdAndDown)
        this.drawer = false;
    }
  },

  methods: {
    async targetClick(target: string) {
      if (!target) return;
      await this.$nextTick();
      await waitForReadystate();
      try {
        this.$vuetify.goTo(`#${target}`, this.options).catch(() => null);
        this.$router.push(`#${target}`).catch(() => null);
      } catch (e) {
        return;
      }
    }
  },
  async created() {
    if (!this.$route.hash) return;

    await this.$nextTick();
    await waitForReadystate();

    this.$vuetify.goTo(this.$route.hash, this.options);
  }
});
